

















































import MainShell from "./MainShell.vue";

export default MainShell.extend({
  name: "Home",
});
